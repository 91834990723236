import React, { Component } from "react"
import styled from "styled-components"
import Header from "../../components/profissionaisSaude/Header"
import Footer from "../../components/profissionaisSaude/Footer"
import Button from "../../components/profissionaisSaude/Inputs/Button"
import DoctorsImage from "../../assets/images/profissionais-saude.svg"
import colors from "../../styles/tokens/colors"
import fontSize from "../../styles/tokens/fontSize"
import { Step } from "../../components/profissionaisSaude/stepper"
import { diversityData } from "../../utils"

const Container = styled.div`
  main {
    display: flex;
    width: 90%;
    max-width: 69.375rem;
    margin: 2rem auto 9.6875rem auto;
    justify-content: space-between;
    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
      section {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    }
    section {
      width: 100%;
      h1 {
        font-size: ${fontSize.fontSize.fontSize2xL};
      }
      p {
        font-size: ${fontSize.fontSize.fontSizeXL};
      }
    }
    .resume-side {
      max-width: 33.75rem;
      .answers {
        margin: 2rem 0;
        align-items: center;

        .step {
          width: 100%;
          height: 5.25rem;
          display: flex;
          gap: 1.5rem;
          align-items: start;
          .answer {
            width: 100%;
          }
          p {
            font-size: ${fontSize.fontSize.fontSizeBase};
            font-weight: bold;
          }
          li {
            max-width: fit-content;
            div {
              margin: 0;
            }
          }
        }
        .step:not(:last-child) {
          li::after {
            content: "";
            height: 2rem;
            margin-top: 0.25rem;
            background-color: ${colors.secondary.health_green};
            border: 1px solid ${colors.secondary.health_green};
          }
        }
        .disabilities {
          display: flex;
          flex-direction: column;
        }
      }
    }
    .img-side {
      max-width: 30.75rem;
      display: flex;
      gap: 1rem;
      flex-direction: column;
      align-items: flex-end;

      span {
        font-size: ${fontSize.fontSize.fontSizeXS};
        margin: -2rem 1.75rem 0 0;
      }
      .anchor-image {
        cursor: pointer;
        color: #515151;
        text-decoration: underline;
      }
    }
  }
`

class Resumo extends Component {
  render() {
    const answers = this.props.location.state?.answers
    return (
      <Container>
        <Header />
        {answers ? (
          <main>
            <section className="resume-side">
              <h1>Seu cadastro foi concluído!</h1>
              <p>
                Você pode mudar essas informações de cadastro a qualquer momento
                clicando no botão “Diversidade” em seu painel.
              </p>
              <div className="answers">
                {diversityData.map(questionData => {
                  return (
                    <div className="step">
                      <Step type="previous" />
                      <div className="answer">
                        <p>{questionData.question}</p>
                        {questionData.type === "radio" ? (
                          <span>{answers[questionData.key].value}</span>
                        ) : (
                          <div className="disabilities">
                            {answers[questionData.key].map(answer => {
                              return <span>{answer.value}</span>
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            </section>
            <section className="img-side">
              <DoctorsImage />
              <span>
                Ilustrado por{" "}
                <a className="anchor-image" href="https://br.freepik.com/">
                  Freepik
                </a>
              </span>
              <p>
                Tudo certo com seu cadastro? Agora você faz parte da rede de
                profissionais da Lacrei Saúde!
              </p>
              <Button
                title="Corrigir"
                link="/saude/cadastro-diversidade"
                variant="secondary"
              />
              <Button
                title="Confirmar"
                link="/saude/painel-inicial"
              />
            </section>
          </main>
        ) : (
          <main>
            <h1>
              Esta página não deve ser acessada diretamente, tente começar pelo
              caminho /saude/diversidade/cadastro
            </h1>
          </main>
        )}
        <Footer />
      </Container>
    )
  }
}

export default Resumo
